import axios from 'axios'
import { useAuthStore } from '../store'
import router from '../router'

const API_URL = import.meta.env.VITE_API_INVESTOR

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: API_URL,
})

api.interceptors.request.use(
  (config) => {
    const { tokenBackoffice, email } = useAuthStore()
    if (tokenBackoffice && config.headers) {
      config.headers.Authorization = `Bearer ${tokenBackoffice}`
      config.headers.email = `${email}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      router.push('/login')
      Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
)

export default api
