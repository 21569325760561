import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Amplify } from 'aws-amplify'
import * as Sentry from '@sentry/vue'

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: import.meta.env.VITE_AWS_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_AWS_WEB_CLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_SRP_AUTH',
  },
  Analytics: {
    disabled: true,
  },
})

import App from './App.vue'
import router from './router'

// Quasar & styles imports
import { Quasar, Notify } from 'quasar'
import langPtBR from 'quasar/lang/pt-BR'
import '@quasar/extras/material-icons/material-icons.css'
import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@fortawesome/fontawesome-pro/css/thin.css'
import '@fortawesome/fontawesome-pro/css/duotone.css'
import '@fortawesome/fontawesome-pro/css/brands.css'
import '@fortawesome/fontawesome-pro/css/solid.css'
import '@fortawesome/fontawesome-pro/css/regular.css'
import '@fortawesome/fontawesome-pro/css/sharp-light.css'
import '@fortawesome/fontawesome-pro/css/sharp-solid.css'
import '@fortawesome/fontawesome-pro/css/sharp-regular.css'
import '@fortawesome/fontawesome-pro/css/sharp-light.css'
import 'quasar/src/css/index.sass'
import './styles/360-styles.scss'
import './styles/360-color-palette.scss'

import '@quasar/quasar-ui-qcalendar/dist/index.css'

const pinia = createPinia()
const app = createApp(App)

app.config.globalProperties.$filters = {
  formatCurrencyValue(value = 0) {
    return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  },
}

app.use(Quasar, {
  plugins: {
    Notify,
  },
  config: {
    notify: {
      position: 'bottom',
      timeout: 2000,
      group: false,
    },
  },
  lang: langPtBR,
})

Sentry.init({
  app,
  dsn: 'https://1f0778e4fbdc4eaebf9f32c7e7af967c@o4504135013105664.ingest.us.sentry.io/4507977010446336',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  environment: import.meta.env.VITE_NODE_ENV,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
})

app.use(pinia)
app.use(router)
app.mount('#app')
