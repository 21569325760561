import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Auth } from 'aws-amplify'
import { useStorage } from '@vueuse/core'
import router from '../router'

import { Notify } from 'quasar'
export const useAuthStore = defineStore('auth', () => {
  const isLoading = ref(false)

  const email = useStorage('email', '')
  const tokenBackoffice = useStorage('tokenBackoffice', '')
  const codeSent = ref(false)
  const userDept = useStorage('userDept', null)

  const cognitoUser = ref(null)

  const isAuthenticated = () => !!tokenBackoffice.value

  const login = async (accessEmail: string, password: string) => {
    isLoading.value = true
    await Auth.signIn(accessEmail, password)
      .then(async (user) => {
        const challenge = user.challengeName
        if (challenge === 'NEW_PASSWORD_REQUIRED') {
          email.value = accessEmail
          cognitoUser.value = user
          router.push('/temporary-password')
        } else {
          userDept.value = user.attributes['custom:profileId'] ?? null
          tokenBackoffice.value = user.signInUserSession.accessToken.jwtToken
          email.value = user.attributes.email
          router.push('/home')
        }
      })
      .catch((err) => {
        Notify.create({
          message: err.message,
          color: 'negative',
          icon: 'warning',
        })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const logout = () => {
    // Logout logic
    tokenBackoffice.value = ''
    email.value = ''
    userDept.value = null
    Auth.signOut().then(() => {
      router.push('/login')
    })
  }

  const sendResetPasswordCode = async (accessEmail: string) => {
    isLoading.value = true
    await Auth.forgotPassword(accessEmail)
      .then(() => {
        codeSent.value = true
        Notify.create({
          message: `Código enviado para o email ${accessEmail}.`,
          color: 'positive',
          icon: 'check',
        })
      })
      .catch((err) => {
        Notify.create({
          message:
            err.code === 'UserNotFoundException'
              ? 'Usuário não encontrado.'
              : err.code === 'NotAuthorizedException'
              ? 'Usuário inativo.'
              : err.message,
          color: 'negative',
          icon: 'warning',
        })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const resetPassword = async (accessEmail: string, code: string, password: string) => {
    isLoading.value = true
    await Auth.forgotPasswordSubmit(accessEmail, code, password)
      .then(() => {
        Notify.create({
          message: 'Senha alterada com sucesso.',
          color: 'positive',
          icon: 'check',
        })
        router.push('/login')
      })
      .catch((err) => {
        Notify.create({
          message: err.message,
          color: 'negative',
          icon: 'warning',
        })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const resetTemporaryPassword = async (password: string) => {
    isLoading.value = true
    await Auth.completeNewPassword(cognitoUser.value, password)
      .then(async (user) => {
        const token = user.signInUserSession.accessToken.jwtToken
        tokenBackoffice.value = token
        router.push('/home')
      })
      .catch((err) => {
        Notify.create({
          message: err.message,
          color: 'negative',
          icon: 'warning',
        })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  return {
    email,
    userDept,
    tokenBackoffice,
    isAuthenticated,
    login,
    logout,
    isLoading,
    sendResetPasswordCode,
    resetPassword,
    codeSent,
    resetTemporaryPassword,
  }
})
