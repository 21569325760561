<template>
  <q-layout
    class="window-height window-width justify-center items-center"
    style="overflow-x: hidden"
    view="hHh lPr fFf"
  >
    <q-page-container id="app" class="window-height window-width justify-center items-center">
      <router-view name="header"></router-view>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
})
</script>

<style scoped></style>
