import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useAuthStore } from '../store'
import { Auth } from 'aws-amplify'
import { api } from '../api'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const { tokenBackoffice, userDept } = useAuthStore()

  if (requiresAuth) {
    if (tokenBackoffice.length) {
      Auth.currentSession()
        .then((session) => {
          const idToken = session.getIdToken()
          const isExpired = idToken.getExpiration() * 1000 < new Date().getTime()
          if (isExpired) {
            next('/login')
            return
          }
          if (userDept === null || userDept === '') {
            next()
            return
          }
          api
            .get(`/profile-user-api?id=${userDept}`)
            .then(({ data }) => {
              const [department] = data
              const userRoutes = department.routes
              if (userRoutes.includes(to.name)) {
                next()
              } else {
                next('/home')
              }
            })
            .catch((error) => {
              next('/home')
              console.error('Error fetching available routes:', error)
            })
        })
        .catch(() => {
          next('/login')
        })
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
